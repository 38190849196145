.widget--jobs {
  max-width: 1070px;
  margin: 0 auto;
  .col--1xs:nth-child(odd) {
    border-right: 1px solid #f6f6f6;
  }
  .col--1xs:nth-child(even) {
    .widget__item:nth-child(odd) {
      padding: 5% 5% 5% 13%;
    }
  }
  h3 {
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
  }
  .widget__item {
    padding: 5% 13% 5% 5%;
  }
  .widget__date {
    margin-top: 14px;
    font-weight: bold;
  }
}

/* MOBILE     --------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1024px) {
  .widget--jobs .widget__item {
    padding:5%;
  }
}