.w-contactform{
  max-width:1280px;
  margin:auto;
  padding:10px;
}
.form-info{
  text-align: center;
  font-weight: bold;
  font-size:16px;
  margin-bottom: 20px;
}
.form {
    form{
      &[data-processing='true']{
        .loader-anim{
          display:block;
        }

        input[type='submit']{
          text-indent:1000px;
        }
      }
    }

    .form-elements {
      .form-loader {
        width: 16px;
        height: 16px;
        background: url('/skins/default/graphics/loading.gif') no-repeat;
        position: absolute;
        bottom: 69px;
        left: 60%;
        display: none;
        margin-left: -30px;
      }

      .form-loader.active {
        display: block;
      }

      .form-info.active {
        display: block;
        margin: 0 0 20px 0;
      }

      .jsvalidate-hint {
        display: none;
      }
      .control-group{
        margin-bottom:10px;
        float:left;
        width:100%;
      }
      }

      #recaptcha{
        padding:5px;
      }

      label {
        display: none;
      }
      .checkbox {
        input{
          float:left
        }
        label {
          display: inline-block;
          font-size:16px;
          padding-left:5px;
        }
      }

      //textarea,
      input,
      select{
        font-size: 18px;
        text-align: left;
        color:#b8b8b8;
        font-family: 'Roboto';
        background:#f5f5f5;
        border:none;
        padding: 10px;
        width:96%;
        box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
      }
      textarea{
        color: black;
        font-size: 18px;
        text-align: left;
        font-family: 'Roboto';
        background:#f5f5f5;
        border:none;
        padding: 10px;
        width:96%;
        box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
      }
      select{
        -webkit-appearance: none;
        -moz-appearance: none;
        
        background-image: url('/skins/default/graphics/sm-down-arrow.png');
        background-size: 15px auto;
        background-position: right 15px center;
        background-repeat: no-repeat;
        border-radius: 0;
      }
      textarea{height:170px !important;max-width:96%;}

      input {
        border-radius: 0;
        -webkit-appearance: none;
        
        &[type="submit"] {
          cursor: pointer;
          background-color:$red;
          text-align: center;
          width: auto;
          color:#FFFFFF;
          float:right;
          margin-top:20px;
          padding:10px 22px;
          &:hover {
            background-color: $red;
          }
        }
        &[type="file"] {
          line-height: 25px;
        }
        &[type="checkbox"] {
          -webkit-appearance: checkbox;
          width:auto;
        }
      }

      .file_upload {
        input {
          display: none;
        }

        label {
          padding-bottom: 30px;
          display: block;
          line-height: 25px;
          border-bottom: 1px solid #a7a7a7;
          color: #3e3e3d;
          cursor: pointer;
          margin-bottom: 20px;
        }

      }

      input:focus,
      select:focus,
      textarea:focus,
      button:focus {
        outline: none;
      }
      .parsley-errors-list{
        float: right;
        color: red;
        width: 100%;
        text-align: right;
        font-size:12px;
      }
}

/* SPINNER LOADER */
.loader-anim {
  position:absolute;
  top:50%;
  left:50%;
  display:none;
  transform:translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 8px;
  background-color:transparent;
}

.loader-anim > div {
  /*background-color: $dark_grey;*/
  height: 100%;
  width: 2px;
  margin-right:1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}


.loader-anim .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader-anim .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.loader-anim .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader-anim .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.3) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.3);
    -webkit-transform: scaleY(0.3);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}

@media screen and (max-width: 768px) {

  .form {
    .form-elements {

      textarea,
      input,
      select{
        padding:10px;
        width:calc(100% - 20px);
      }
      input[type="checkbox"] {
        width:auto;
      }

    }
  }

}

html[dir="rtl"] {

  select,
  textarea,
  input {
    text-align: right;
  }

  .ss-main .ss-content .ss-list {
    text-align: right;
  }
}
.contactform-col [class*="col"]{
  padding:6% 5%;
  font-size:18px;
}