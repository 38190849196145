.widget--timeline {
  .widget__title h2 {
    margin-bottom:10px!important;
  }
  .widget__wrapper {

    .widget__image{
      opacity:0;
      width:356px;
      height:186px;
      display:block;
      margin:0 auto;
      padding:3%;
    }

    .widget__container {
      position: relative;
      max-width: 750px;
      margin: 0 auto;
      overflow: hidden;
      width: 100%;

      .round__button__leftbutton {
        position: absolute;
        bottom:2px;
        left: 0;
        z-index:4;
      }

      .round__button__rightbutton {
        position: absolute;
        bottom:2px;
        right: 0;
        z-index:4;
      }

      .widget__menu__overlay{
        position:absolute;
        width:50%;
        border-bottom:2px solid $red;
        top: 32px;
        z-index: 3;
      }

      .widget__menu_line {
        content:'';
        display:block;
        width:100%;
        height:2px;
        border-bottom:2px solid  #000;
        position: absolute;
        bottom: 12px;
      }

      .widget__menu {

        position:relative;
        border-bottom: 2px solid transparent;
        margin-bottom: 12px;
        margin-top: 32px;
        padding: 0 50px 0 200px;
        z-index:1;
        margin-left:0;
        .widget__item {

          position: relative;
          display: block;
          width: 10px;
          padding-right:5px;
          cursor:pointer;

          .widget__item__date {
            color:#000;
            position: absolute;
            left: 50%;
            right: auto;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            top: -30px;
          }

          .widget__item__dot {
            position: absolute;
            bottom: -9px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 2px solid #000;
            background-color: white;
          }

          .widget__item__caption {
            display:none;
          }

        }

        .widget__item:hover .widget__item__dot,
        .widget__item.on .widget__item__dot{
          background-color: #fff;
          border: 2px solid $red;
        }

        .widget__item:hover .widget__item__date,
        .widget__item.on .widget__item__date{
          color:$red;
        }
      }
    }

    .widget__captions{

      height:200px;
      width:400px;
      margin:0 auto;
      position:relative;
      max-width:100%;

      .widget__caption {
        position:absolute;
        width:100%;
        opacity:0;
      }

      .widget__caption *{
        text-align:center;
      }

      .widget__caption h3{
        font-size:1.5rem;
        margin-bottom:0;
        margin-top:40px;
      }

      .widget__caption h4{
        margin-top:5px;
        font-size:1rem;
      }
    }


  }
}


@media screen and (max-width: 768px) {
  .widget--timeline {

    .widget__wrapper {

      .widget__container {
        width:90%;
      }
    }
  }
}