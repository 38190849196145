.widget--buddywall {
  @extend .content--centered--width;
  .widget__item {
    float: left;
    position: relative;

    .widget__item__content {
      padding: .75%;
      .widget__item__title {
        position: absolute;
        left: 50%;
        top: 50%;
        span {
          display: block;
          position: relative;
          left: -50%;
          h3 {
            margin: 0;
            font-size: 1.2rem;
            color: $red;
            display: block;
            position: relative;
            text-align: center;
            font-weight: 400;
            top: -11px;
          }
        }
      }
      .widget__item__panel {
        position: absolute;
        background-color: rgba(255, 255, 255, .85);
        height: 13%;
        top: 44%;
        width: 100%;
        min-height: 25px;
      }
      .widget__image {
        object-fit: contain;
        @include transition(all .6s linear);
        img {
          object-fit: cover;
          width: 100%;
        }
      }
      .widget__caption {
        top: 10%;
        width: 80%;
        height: 100%;
        position: absolute;
        text-align: center;
        padding: 5% 10%;
        opacity: 0;
        color: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:1rem;
        > div {
          margin-top:-50px;
        }


        .decorator__button {
          a {
            padding: 8px 12px;
            font-size: .9rem;
          }
        }
      }
    }
    &:hover {
      .widget__item__title {
        top: 50%;
        opacity: 0;
      }
      .widget__caption {
        top: 0;
        opacity: 1;
      }
      .widget__item__panel {
        top: 0;
        height: 100%;
      }
    }
  }
}

/* MOBILE     --------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {

  .widget--buddywall {
    .widget__item {
      .widget__item__content {
          .widget__item__title {
            opacity: 1;
            top:22%;
          }
          .widget__caption {
            p{
              display:none;
            }
            top: 50%;
            opacity: 1;
            display:block;
            > div {
              margin-top:0;
            }
          }
          .widget__item__panel {
            top: 0;
            height: 100%;
            opacity: .7;
          }
      }
    }
  }

}


@media screen and (max-width: 480px) {
  .widget--buddywall .widget__item {
    width:100%!important;
  }

  .widget--buddywall {
    .widget__item {
      .widget__item__content {
        .widget__item__title {
          top: 0;
          opacity: 0;
        }
        .widget__caption {
          p{
            display:block;
          }
          top: 0;
          opacity: 1;
          display:flex;
          > div {
            margin-top:-50px;
          }
        }
        .widget__item__panel {
          top: 0;
          height: 100%;
        }
      }
    }
  }
}