.widget--location {
  padding-bottom:0!important;
  @extend .content--centered--width;

  .widget__item{

    width:100%;

    > div{
      height:450px;
      float:left;
    }

    .widget__caption {
      padding:6% 5%;
      font-size:1.2rem;

      h3{
        margin-bottom:0;
      }

      a[href*=mailto]:before,
      a[href*=linked]:before{
        content: '';
        display: inline-block;
        width: 15px;
        height: 13px;
        margin-right: 6px;
        position:relative;
        margin-bottom:-1px;
      }

      a[href*=mailto]:before {
         background: url(../graphics/icon_email.svg) no-repeat;
      }

      a[href*=linked]:before {
        background: url(../graphics/icon_linkedin.svg) no-repeat;
      }


    }

    &:nth-child(even) {
      .widget__caption {
        float: right;
      }
    }

  }

  .widget__detail {

    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    position:relative;

    .widget__caption {
      padding:0 4%;
      position:absolute;
      top:0;
      bottom:0;
    }
  }

}
.map{
  height:calc(100vh - 54px);
  min-height:400px;
}
.gm-fh{
  position:relative;
  .scroll-down{
      display:block !important;
      bottom:20px;
  }
}

@media screen and (max-width: 768px) {
  .widget--location .widget__item > div {
    height:auto;
  }

  .widget--location .widget__item .map {
    height:300px;
  }
}

