.widget--summary {
  @extend .widget--copy;
  padding-bottom:0!important;
  .widget__detail {
    width: 100%;
    max-width: 1280px;
    .widget__item {
      position: relative;
      h3 {
        font-size: 1.8rem;
        font-weight: 400;
        margin-top: -10px;
      }
      .widget__caption {
        height: 100%;
        display: block;
        text-align: left;
        font-size: 1rem;
        justify-content: initial;
        z-index: 10;
        overflow: hidden;
        padding:0;
        @include transition(all .4s linear);
        .widget__content { 
          padding: 40px;
        }
        .decorator__button a {
            padding: 10px 22px;
          }
      }
      .widget__image {
        position: relative;
        padding: 0;
        img {
          width: 100%;
          margin: 0;
          background: #ebebeb;
        }
      }
      .widget__image:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -25px;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-right: 25px solid white;
        z-index: 2;
        @include transition(all .4s linear);
      }
    }
  }
}

.widget--summary .widget__detail .widget__item {
  .widget__extra {
    opacity: 0;
    visibility: hidden;
    @include transition(all .4s linear);
    .widget__related {
      margin-top: 40px;
      overflow:hidden;
      .related__project {
        .related__name:before {
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          left: 0;
          top: -6px;
          background: url(../graphics/icon_projects-2x.png);
          background-size: 16px 16px;
        }
      }
      .related__person {
        .related__name:before {
          display: block;
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          left: 0;
          top: -4px;
          background: url(../graphics/icon_team-member-2x.png);
          background-size: 16px 16px;
        }
      }
      .related__name {
        padding-top: 15px;
        position:relative;
        opacity:0;
      }
      & > div {
        float:left;
      }
      & > div div {
        width: 100px;
        float:left;
        margin-left:5px;
        font-size: .9rem;
        a img {
          height: 100px;
          object-fit: cover;
          opacity:0.5;
        }
        &:first-child {
          margin-left:0;
        }
        &:hover {
          img {
            opacity:1;
          }
          .related__name {
            opacity:1;
          }
        }
      }
    }
    .widget__share .widget__share--options {
      background: #FFE3E4;
    }
  }
}
.Summary.SummeryDetail{
  #content{
    margin-top:54px;
  }
}
.summary_detail_image{
  .widget__image{
     img{
       width:100%;
       max-height:560px;
       object-fit:cover;
     }
  }
}

.widget--summary .widget__share {
  &.active .widget__share--options {
    right: -5px;
  }
  margin-top: 20px;
}

.widget--summary .widget__detail .widget__item.active {
  .widget__footer {
    .widget__share.active .widget__share--options {
      background: #FFE3E4;
    }
  }
  .widget__dots {
    display: none;
  }
  .widget__image:before {
    border-right: 25px solid #FFE3E4;
  }
  .widget__share.active {
    width: 100px;
  }
  .widget__caption {
    z-index:100;
    background: #FFE3E4;
    span {
      opacity:1;
    }
    .decorator__button {
      bottom: 6%;
    }
    .widget__extra {
      opacity: 1;
      visibility: visible;
      .widget__share {
        position: absolute;
        right: 4%;
      }
    }
  }
}

.widget--summary .placeholder {
  opacity:1;
  &.loaded {
    opacity:0;
    display: none;
    .widget__image {
      height:0;
    }
  }
  .widget__image {
    height:400px;
    background: #ebebeb;
  }
  h3 {
    height:41px;
    background: #ebebeb;
  }
  p {
    color:#ebebeb;
    background: #ebebeb;
  }
}

.widget--summary .widget__footer {
  height:49px;
  /*position:absolute;
  bottom: 40px;
  left: 40px;*/
  width: 100%;
  margin-right: 40px;
  @include box-sizing;
  .widget__share {
    position:absolute;
    margin-top: 20px;
    right: 40px;
    span {
      opacity:1!important;
    }
  }
}

.widget--summary .widget__detail .widget__item.active:nth-child(even) .widget__image:before {
  border-left: 25px solid #FFE3E4;
}

.widget--summary .widget__detail .widget__item:nth-child(odd) {
  .widget__caption {
    left: 50%;
  }
}

.widget--summary .widget__detail .widget__item:nth-child(even) {
  .widget__image {
    float: right;
  }
  .widget__image:before {
    right: auto;
    left: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid white;
    border-right: 0;
  }
}

@media screen and (max-width: 1250px) {
  .widget.widget--summary {
    .widget__caption span {
      opacity:0;
      display:none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .widget.widget--summary {
    padding-top: 6% !important;
    .widget__item.active {
      .widget__show {
        opacity:1;
      }
    }
    .widget__show {
      opacity:0;
    }
  }
}

@media screen and (max-width: 768px) {
  .widget--summary {
    .col--container.col--row2 .col--1xs {
      width: 50% !important;
    }
    padding-top: 0;
  }

}

@media screen and (max-width: 481px) {
  .widget--summary {
    .col--container.col--row2 .col--1xs {
      width: 100% !important;
    }
    padding-top: 0;
  }

  .widget.widget--summary {
    .widget__item {
      .widget__show {
        opacity: 1;
      }
    }
  }

  .widget.widget--summary .widget__caption span {
    opacity:1;
    display:inherit;
  }
  
  .widget.widget--summary {
    .widget__footer {
      left:6%;
    }

   .widget__detail .widget__item .widget__caption .widget__content {
      padding-bottom:12%!important;
    }

    .widget__item h3 {
      font-size: 1.3em;
    }
  }

  .widget--summary .widget__detail .widget__item .widget__caption .widget__content {
    padding:6%!important;
  }

  .widget--summary .widget__detail .widget__item .widget__extra {
    opacity: 1;
    visibility: visible;
  }
  body .widget--summary .widget__detail .widget__caption {
    position: static;
  }
  .widget--summary .widget__detail .widget__item .widget__caption .decorator__button--red {
    /*display: none;*/
  }
  .widget--summary .widget__detail .widget__item .widget__image:before {
    display: none;
  }
  .widget--summary .widget__image img {
    width: 100%;
  }
  .widget--summary .widget__detail .widget__item h3 {
    margin-top: 0;
  }

  .widget--summary .widget__detail .widget__item .widget__extra .widget__related .related__name,
  .widget--summary .widget__detail .widget__item .widget__extra .widget__related > div div a img {
    opacity:1;
  }

  .widget--summary .widget__footer .widget__share {
    left:auto;
    right: 0;
    padding-top: 0;
  }
}