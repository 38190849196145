.normal-nameplate{
    max-width: 1280px;
    margin:auto auto 40px auto;
    padding-left: 10px;
    padding-right: 10px;
    h1{
        margin-bottom:5px;
        font-family: 'Platform Web';
        font-weight:normal;
    }
    h3{margin-top:2px;font-weight:normal;}
}
.searchResults {
    padding-top:30px;
    font-size:16px;
    .pagination-wrap{
        background:#ebebeb;
        .pagination-controls{
            max-width: 1280px;
            margin:auto;
        }
    }
}
.search-result{
    background:#ebebeb;
    padding-top:40px;
    padding-bottom:40px; 
    ul{
        max-width: 1280px;
        margin:auto;
        padding-left: 10px;
        padding-right: 10px;
        li{
            max-width:750px;
        }
    }
    .search-result-morelink{
        margin-bottom:30px;
        margin-top:6px;
        a{
            background: url(../graphics/sm-arrow-right.png) left 5px no-repeat;
            background-size: 4px;
            padding-left: 10px;
        }
    }
}

#searchForm {
    position:relative;
    margin-left:15px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    width:22px;
    @media screen and (max-width: 768px) {
        display:none;
    }
    
}
.search-img {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background:#FFF;
    img{
        max-width:100%;
        height:auto;
    }
}
.search-btn {
    width: 22px;
    height: 22px;
    padding: 0;
    border: none;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    text-indent: -999em;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.widget__share.active a{
    visibility: hidden;
}
input.search_field {
    width: calc(100% - 30px);
    border: none;
    border-radius:0;
    font-size:14px;
    color:#8c8a8a;
    border-bottom: 1px solid #8c8a8a;
    font-family:  'Roboto', sans-serif ;
    outline:none !important;
    -webkit-appearance: none;
}
#searchForm.search-slide {
    width: 200px;
}
.searchformMobile{
    display:block;
    position:relative;
    width:200px;
    margin:20px auto;
    padding:0 0 0 20px;
    .searchform{
        input{
            font-family: 'Roboto', sans-serif !important;
        }
    }
}
.mobileSearchContainer{
    border:none !important;
    border-color:#FFF !important;
    margin-top:10px !important;
    .search-img{
        top:8px;
    }
}

.search-res-img{
    float:left;
    margin-right:10px;
}
.search-result-title{
    margin-bottom:2px;
}
