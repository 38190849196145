@import 'widgets/_gallery.scss';
@import 'widgets/_copy.scss';
@import 'widgets/_whats-happen.scss';
@import 'widgets/_summary.scss';
@import 'widgets/_timeline.scss';
@import 'widgets/_people.scss';
@import 'widgets/_buddywall.scss';
@import 'widgets/_jobs.scss';
@import 'widgets/_location.scss';

.widget__item__readmore{
  text-align:center;
  margin-top:10%;
}

.widget {
  padding:3% 0;
  .widget__title {
    h2, h1 {
      margin-top:0;
      margin-bottom: 3%;
      padding: 0;

      font-size: 2rem;
      font-weight: 300;
      text-align: center;
    }
  }
}

.two--column--copy{
  margin-top:3%;
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;

  -webkit-column-gap: 30px; /* Chrome, Safari, Opera */
  -moz-column-gap: 30px; /* Firefox */
  column-gap: 30px;
}
.widget.widget--copy {
  padding:6%;
}

/* MOBILE     --------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 840px) {
  .two--column--copy{
    column-count: 1;
  }

  .widget {
    padding: 6% 0;
    .widget__title {
      h2 {
        margin-bottom: 6%;
      }
    }
  }
  
  .widget.widget--copy {
    padding:6%;
  }

  .widget.widget--summary {
    padding:0;
  }


  iframe[src*='www.youtube.com'] {
    width:100%;
  }
}