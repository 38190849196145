#tinymce{
  .indented{
    span{
      display:block;
    }
  }
}
.scroll-down{
  display:none;
  background: url('../graphics/arrow_home-down-ro.svg') center center no-repeat;
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.widget--gallery {
  position: relative;
  &:not(.gallery--inline) {
    padding:0!important;
  }
  .widget__gallery {

    overflow: hidden;
    width: 100%;
  }
  .widget__image {
    object-fit: contain;
    @include transition(all .6s linear);
    img {
      object-fit: cover;
      height:calc(100vh - 54px);
      margin: auto;
    }
    .widget__caption {
      display: none;
    }

    .projectImg{
      min-height: unset;
      height: calc(100vh - 200px);
      width: auto;

    }


    .gallery-overlay{

      display:none; /* faded in by javascript */
      position:absolute;
      top:50%;
      left:50%;
      transform:translateY(-50%) translateX(-50%);
      color: #ffffff;

      &.red{
        color:$red;
      }

      &.static{

        top:40%;
        font-family: 'Platform Web', sans-serif;
        font-size: 4rem;
        text-align: center;
        width: 45%;
        letter-spacing:.15rem;

      }

      &.indented {
        letter-spacing: 1px;
        font-family: 'Platform Web', sans-serif;

        > span {

          display:block;
          font-size: 8rem;
          white-space :nowrap;
          line-height: 6.7rem;

          &:first-child {

            + span {
              text-indent: 19.4%;
              white-space: initial;
            }
          }

          &:last-child {
            text-indent:19.4%;
            font-size: 2rem;
            margin-top: 35px;
            line-height:1.6rem;
          }

        }
      }
    }
  }
}
.widget--gallery--fh{
  @media (min-width: 1025px) {
    height:calc(100vh - 54px);
    .owl-height{
      height:calc(100vh - 54px) !important;
    }
    .scroll-down{
      display:block;
      bottom: 60px;
    }
  }
}

#project .widget--gallery{

  height:calc(100vh - 200px);

  @media (min-width: 1025px) {
    height:calc(100vh - 200px);
    .owl-height{
      height:calc(100vh - 200px) !important;
      .widget__image img{
        height:calc(100vh - 200px);
        img{
          height:calc(100vh - 200px);
          width:auto;
          object-fit:contain;
        }      }
      .scroll-down{
        display:none;
        bottom: 120px;
      }
    }
  }

}

.widget--gallery .owl-nav {
  top: 50%;
  width: 100%;
  position: absolute;
  &.disabled {
    display: none;
  }
  &>div {
    top: -50px;
    position: inherit;
    width: 50px;
    height: 50px;
    background: white url('../graphics/arrow_gallery-left.png') 41% 50% no-repeat;
    text-indent: -50px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      background: $red url('../graphics/arrow_gallery-left-ro.png') 41% 50% no-repeat;
    }
  }
  .owl-next {
    right: 0;
    background: white url('../graphics/arrow_gallery-right.png') 59% 50% no-repeat;
    &:hover {
      background: $red url('../graphics/arrow_gallery-right-ro.png') 41% 50% no-repeat;
    }
  }
  .owl-full {
    opacity: 0;
    right: 54px;
    background: url('../graphics/expand_gallery.png') 50% 50% no-repeat;
    background-size: contain;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: url('../graphics/expand_gallery-ro.png') 50% 50% no-repeat;
      background-size: contain;
    }
    &.active {
      opacity: 1;
    }
  }
}

.widget__gallery {
   .widget__image {
     height: 100%;
   }
}


.widget--gallery {

  .owl-stage-outer {
    height: 100%;
  }
  .owl-stage {

      height: 100%;

        .owl-item {
          height: 100%;
        }

      }

      .owl-height{
          max-height: 900px;

            .widget__image {
              height: 100%;
            }

            .scroll-down{
              display:none;
              bottom: 120px;
            }
        }
}

.widget--gallery.widget--gallery--fh {
    max-height: 900px;
}

.widget--gallery.widget--gallery--tabs {
  padding-bottom: 0;
  &+script+.widget {
    padding-top: 7%;
  }
  .scroll-down{
    bottom: 120px !important;
  }

    height:calc(100vh - 54px);
    max-height: 900px;

    .owl-stage {

      height: 100%;

        .owl-item {
          height: 100%;
        }

      }

       .owl-height{
          height:calc(100vh - 54px) !important;
          max-height: 900px;

            .widget__image {
              height: 100%;
            }

            .scroll-down{
              display:none;
              bottom: 120px;
            }
        }

        @media (max-width: 700px) {
          .owl-height{
            height:calc(100vh - 54px) !important;
            max-height: 280px;
          }
        }


}

@media (max-width: 700px) {
          .widget--gallery.widget--gallery--tabs {
            height:calc(100vh - 54px) !important;
            max-height: 280px;
          }
        }

.owl-dots {
  display: none;
  position: relative;
  top: 4px;
  .owl-dot {
    width: 20px;
    height: 20px;
    background: white;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    &.active {
      background: $red;
    }
  }
}
.scrolled{
  opacity:0;
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
   -moz-transition: opacity 1s ease-in-out;
   -ms-transition: opacity 1s ease-in-out;
   -o-transition: opacity 1s ease-in-out;
}

/* INLINE ------------------------------------------------------------------------------------------------------------*/

.widget--gallery.gallery--inline {
  width: 100%;
  max-width: 700px;
  margin: 0 auto 0 auto;
  h4 {
    margin-top:0;
    display: block;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .widget__gallery {
    max-height: 86px;
  }
  .widget__image {
    img {
      object-fit: cover;
      min-height: initial;
      width: 100%;
      max-height: 100px;
    }
    .ie--object-fit {
      max-height: 90px;
    }
  }
}

.widget--gallery.gallery--inline {
  .owl-nav {
    & > div {
      top: 5px;
      right: -100px;
    }
    .owl-next {
      background: white url('../graphics/arrow_right-2x.png') 41% 50% no-repeat;
      background-size: 20px 35px;
      &:hover {
        background: white url('../graphics/arrow_right_black@2x.png') 41% 50% no-repeat;
        background-size: 20px 35px;
      }
    }
    .owl-prev {
      right:auto;
      left: -100px;
      background: white url('../graphics/arrow_left-2x.png') 41% 50% no-repeat;
      background-size: 20px 35px;
      &:hover {
        background: white url('../graphics/arrow_left_black@2x.png') 41% 50% no-repeat;
        background-size: 20px 35px;
      }
    }
  }
}

.widget--gallery.gallery--inline {
  .owl-dots {
    display: block;
    text-align: center;
    .owl-dot {
      width: 15px;
      height: 15px;
      background: #ebebeb;
      display: inline-block;
      margin-right: 6px;
      cursor: pointer;
      &.active {
        background: $red;
      }
    }
  }
}


/* HOMEPAGE CAPTIONS -------------------------------------------------------------------------------------------------*/

#tinymce {
  @extend .widget__captions;
}

.widget--gallery .widget__captions {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
  z-index: 1;
  > div {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }
}

.widget--gallery .widget__captions .widget__caption {
  width: 33%;
  height: 55px;
  float: left;
  overflow: hidden;
  position: relative;
  bottom: 0;
  margin: 0 1px;
  background: white;
  @include transition(height .3s linear,
  bottom .3s linear);
  h3 {
    margin: 0;
    height: 55px;
    cursor: pointer;
    line-height: 55px;
    font-weight: 400;
    font-size: 1.25rem;
    background: white;
    padding-left: 25px;
    @include transition(background 1s linear);
  }
  .caption__detail {
    padding: 0 20px 20px 20px;
    position: relative;
    margin-top: 0;
    font-size: 1.3em;
    font-weight: 300;
  }
  .caption__links {
    position: absolute;
    top: 140px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  [class*="decorator__button"] {
    &.inactive a {
      color: #bebebe;
    }
    width: 100%;
  }
  .decorator__button--more.inactive:after {
    background: url(../graphics/icon_right-arrow-small-ro.png);
  }
  .decorator__button--project.inactive:after {
    background: url(../graphics/icon_projects-ro.png);
  }
  .decorator__button--who.inactive:after {
    background: url(../graphics/icon_team-member-ro.png);
  }
}

.widget--gallery .widget__captions .widget__caption {
  &.active {
    h3 {
      background: $red;
      color: white;
    }
    &:after {
      background: url(../graphics/arrow_home-up-ro.svg) 50% 50% no-repeat;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &.on {
    height: 332px;
    bottom: 277px;
    color: black;
    h3 {
      background: $red;
      color: white;
    }
    &:after {
      background: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 16px;
    right: 25px;
    display: block;
    width: 25px;
    height: 25px;
    pointer-events: none;
    background: url(../graphics/arrow_home_up.svg) 50% 50% no-repeat;
  }
}


/* overlay at start */

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}


/* overlay animate in */

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}


/* overlay animate out */

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}


/* content at start */

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}


/* content animate it */

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}


/* content animate out */

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}


/* MOBILE HIGH DENSITY  ----------------------------------------------------------------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
  .widget--gallery .owl-nav .owl-next {
    background: white url("../graphics/arrow_gallery-right-2x.png") 59% 50% no-repeat;
    background-size: contain;
  }
  .widget--gallery .owl-nav .owl-prev {
    background: white url("../graphics/arrow_gallery-left-2x.png") 59% 50% no-repeat;
    background-size: contain;
  }
  .owl-full {
    background: url('../graphics/expand_gallery@2x.png') 50% 50% no-repeat;
    background-size: contain;
  }
}


/* MOBILE     --------------------------------------------------------------------------------------------------------*/


@media screen and (max-width: 700px) {
  .mobile-caption-container {
    padding: 15px;
    h3 {
      margin: 0 0 15px 0;
      padding-bottom: 15px;
      color: #e84242;
      font-weight: 500;
      font-size: 1.25rem;
      border-bottom: 1px solid #e84242;
    }

    p {
        font-size: 1rem;
        margin: 0;
        font-weight: 300;
      }

      .decorator__button, .decorator__button--red, .decorator__button--white {
        display: block;
      }

      .caption__links {
        margin: 30px 0 45px 0;
      }
  }
}

@media screen and (max-width: 840px) {
  .widget--gallery.gallery--inline {
    .owl-nav {
      & > div { top: -28px; }
      .owl-prev { left: 0; }
      .owl-next { right: -5px; }
    }
  }

  .widget--gallery.gallery--inline .widget__image img {
    object-fit: contain;
  }
}

@media screen and (max-width: 768px){

  .widget--gallery{
    .widget__image {
      .gallery-overlay {

        &.static{
          font-size: 3rem;
          width: 40%;
        }

        &.indented {
          top: 45%;
          left: 45%;

          > span {
            font-size: 7.2rem;
            line-height: 6rem;
            text-indent:0 !important;

            &:last-child {
              line-height:1rem;
              text-indent:0;
            }

          }
        }
      }
    }
  }

  .widget--gallery.gallery--inline .owl-dots {
    display:none;
  }
  .widget--gallery .widget__captions .widget__caption .caption__links {
    margin-right: 0;
    top:120px;
    width:50%;
  }

  .widget--gallery .widget__captions .widget__caption .caption__detail {
    padding-top:0;
    p, .caption__links {
      width:40%;
      float:left;
      position:static;
    }
    .caption__links {
      width:50%;
      margin-left:10%;
      margin-top:0.5em;
    }
  }

  .widget--gallery.gallery--inline .widget__gallery {
    margin-bottom: 20px;
    padding-bottom: 60px;
  }

  .widget--gallery .widget__captions {
    bottom: -165px;
    z-index: 10;
    >div {
      position: absolute;
      bottom: 0;
    }
    .widget__caption {
      width: 100%;
      float: none;
      border-bottom: 1px solid $red;
      &:last-child {
        border-bottom: 0;
      }
      &.on {
        bottom: auto;
        max-height: 400px;
      }
    }
  }
  .widget--gallery.widget--gallery--tabs+script+.widget {
    padding-top: 180px;
  }
  .widget--gallery .widget__captions .widget__caption.on {
    height: 210px;
  }
}

#project .widget--gallery .widget__image {
  background-image: none !important;
}

#project .widget--gallery .widget__image img {
  visibility: visible !important;
}

#project .widget--gallery.background--grey {
  background: #fff !important;
}

@media screen and (max-width: 700px) {

  #project .widget__gallery {
    height: unset;
  }

  #project .widget--gallery {
    max-height: unset;
  }

  #project .widget--gallery .widget__image {
    height: unset;
    margin-top: 15px;
    background-image: none !important;
  }

  .widget--gallery .widget__image iframe,
  .widget--gallery .widget__image img {
    min-height: unset;
    height: unset;
    width: 100%;
  }

  #project .widget__gallery img {
    width: 93%;
  }

}

@media screen and (max-width: 600px) {
  .widget--gallery.gallery--inline .widget__image img {
    margin: 0 auto;
  }


  .widget--gallery .widget__image .projectImg {
    min-height: unset;
    height: calc(100vh - 200px);
    width: auto;
  }


}


@media screen and (max-width: 481px) {

  .widget--gallery .widget__image .projectImg {
    width: 100%;
    height: unset;
}
#project .widget--gallery {
  height: unset;
}
  #project .widget--copy .widget__detail > div {
    width:100%!important;
  }

  .widget--gallery{
    .widget__image {

      .gallery-overlay {
        top: 40%;

        &.static{
          font-size: 2rem;
          width:50%;
        }

        &.indented {

          > span {
            font-size: 3.6rem;
            font-weight: 500;
            line-height: 3.2rem;
            &:first-child {
              + span {
                text-indent: 16.1%;
              }
            }

            &:last-child {
              text-indent: 16.1%;
              font-size: 1.4rem;
              line-height: 1.5rem;
              white-space: pre-wrap;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .widget--gallery .owl-nav > div {
    top: -25px;
  }

  .widget--gallery .widget__captions .widget__caption .caption__detail {
    padding-top:0;
    p, .caption__links {
      width:100%;
      float:none;
    }
    .caption__links {
      margin:0;
    }
  }

  .widget--gallery .widget__captions .widget__caption.on {
    height: 310px;
  }


}
/* iphone 8*/
@media (max-height: 375px) and (max-width: 667px) {
  .widget--gallery.widget--gallery--tabs {
    height: calc(100vh - 94px) !important;

}

}
@media (max-height: 500px) and (max-width: 850px) {

  #project .widget--gallery {
       height: unset;
  }

  .widget--gallery.widget--gallery--fh {
     max-height:unset; 
  }


  #project{
    .widget--gallery{
      .widget__image{
        img {
          visibility: visible !important;
          width: 93%;
         padding: 15px 0px 15px 0px;
        }
      }
    }
    .widget__image:last-child{
      img {
        padding: 15px 0px 30px 0px;
      }
    } 
  }

}