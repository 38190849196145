.widget--people {
  max-width: 1280px;
  margin:0 auto;
  padding-bottom:0!important;
  .widget__item {
    height:100%;
    max-height: 330px;
    overflow: hidden;
    position:relative;
    .widget__image img {
      object-fit: cover;
      max-height:100%;
      max-width:100%;
    }
    .widget__captions {
      position:absolute;
      width:100%;
      background: white;
      bottom:0;
      opacity:0.9;
      padding-left: 25px;
      color:$red;
    }
  }
  .widget__item:hover {
    .widget__captions {
      background:rgba($red, 0.9);
      color: white;
    }
  }
}

.People {
  #content {
    margin-top: 108px;
    .widget:first-of-type {
      padding-top: 0;
    }
    .widget .widget__detail {
      max-width: 1280px;
      .col--1xs:first-child {
        padding-left:30px;
        width:auto;
      }
    }
  }
}

.Summary {
  @extend .People;
  padding-top:4%;
  
  .widget__detail img {
    max-width: 330px;
    margin:0;
  }

  .widget__detail h2 {
    text-align: left;
    margin:0 0 40px 0;
    font-weight: 400;
  }
}
.Summary.SummaryDetail{
  padding-top:0;
}

@media screen and (max-width: 840px) {
  .People {
    #content {
      .widget .widget__detail {
        .col--1xs:first-child {
          padding-left:0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .People #content .widget .widget__detail .col--1xs:first-child {
    img {
      float:left;
      width:50%;
    }
  }
}

@media screen and (max-width: 480px) {
  .People #content .widget .widget__detail .col--1xs:first-child {
    img {
      float:none;
      width:100%;
    }
  }
}