.widget--buddys {
  @extend .content--centered--width;
  background: white url('../graphics/gallery-loading.gif') 50% 50% no-repeat!important;
  h2 {
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
    text-align: center;
  }
  .col--container {
    opacity: 0;
    @include transition(all .6s linear);
  }
  &.widget--active {
    background: none!important;
    .col--container {
      opacity: 1;
    }
  }
}

.widget--buddys .widget__item {
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .widget__detail {
    overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 0;
    padding: 20px 20px 40px 20px;
    font-size: 1.125rem;
    font-weight: 300;
    width: 100%;
    @include box-sizing();
    p {
      font-size: 0.87rem;
      font-weight: inherit;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}

.widget--buddys .widget__item.widget__item--person {
  p {
    margin-top: 12px;
    font-size: 0.87rem;
  }
}

.widget--buddys .widget__item .widget__detail .widget__footer {
  color: $red;
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 35px;
  width: 100%;
  a {
    color: inherit;
  }
  &>div {
    position: absolute;
  }
  .widget__detail {
    height: 50%;
  }
  .widget__share a {
    padding-right: 20px;
    font-weight: normal;
  }
  .widget__read_more a {
    font-weight: 300;
    padding-left: 20px;
    font-size: 0.87rem;
    &:hover {
      color:black;;
    }
  }
}

.widget--buddys .widget__item.col--2xs {
  img {
    height: 100%;
    max-height: 441px;
  }
  .widget__detail {
    padding: 10px 20px 15px 20px;
    h3 {
      margin-top: 5px;
      margin-bottom: 0;
    }
    .widget__footer {
      top: 20px;
      width: 210px;
      right: 0;
      left: auto;
      position: absolute;
      .widget__read_more {
        right: 85px;
        a {
          padding-left: 0;
        }
      }
    }
  }
}

.widget--buddys .widget__item.widget__item--square {
  .col--container {
    height: 50%;
    .col--1xs {
      height: 100%;
    }
  }
  .widget__detail {
    height: 50%;
  }
}


/* MOBILE     --------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 1281px) {}

@media screen and (max-width: 1025px) {
  .widget--buddys h2 {
    margin: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .widget--buddys .widget__item img {
    max-height: 400px;
  }
  .widget--buddys .widget__item.col--2xs .widget__detail {
    min-height: 80px;
    h3 {
      padding-bottom: 35px;
    }
    .widget__footer {
      width: 100%;
      top: auto;
      overflow: hidden;
      height: 20px;
      bottom: 20px;
      .widget__read_more {
        left: 20px;
        right: auto;
      }
    }
  }
  #content .widget--buddys .col--container.col--row2 .col--1xs {
    width: 50%;
  }
}