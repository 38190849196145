@media screen and (max-width: 768px) {
  body * {
    -webkit-appearance: none;
  }
  
  body #content {
    margin-top:54px;
  }
  
  body #header {
    height:54px;
    border-bottom:1px solid red;
  }

  body #footer ul {
    padding-top: 20px;
    line-height: 30px;
    li {
      display: block;
      margin: 0;
    }
  }

  body .mobile__button { display: block; }
  #mainMenu { 
    opacity:0;
    visibility: hidden;
  }

  body #mobile__menu {
    display:block;
  }
  #header .header__social_link {

    right: 6em !important;
  }
  #header .header__logo {
    width: 65px !important;
    height: 33px !important;
    top: 12px !important;
    right: 1em !important;

  }
}

@media screen and (max-width: 481px) {
  #content h2 {
    font-size: 1.5em;
    line-height: 2rem;
  }
}

/* MOBILE HIGH DENSITY  ----------------------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
  .widget__share:before {
    background: url(../graphics/icon_share-2x.png);
    background-size: contain;
  }

  .decorator__button--project:after {
    background: url(../graphics/icon_projects-2x.png);
    background-size: contain;
  }

  .decorator__button--more:after {
    background: url(../graphics/icon_right-arrow-small-2x.png);
    background-size: contain;
  }

  .decorator__button--who:after {
    background: url(../graphics/icon_team-member-2x.png);
    background-size: contain;
  }

  #header .header__logo {
    background: url(../graphics/ttsp-logo@2x.jpg);
    background-size: contain;

  }
}
@media screen and (device-aspect-ratio: 40/71) {
  .widget--gallery.widget--gallery--tabs {
    height: unset !important;
    max-height: unset !important;
}

}