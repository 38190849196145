/* FONTS */
@font-face {
  font-family: 'Platform Web';
  src: url('/skins/default/fonts/Platform-Regular-Web.eot');
  src: url('/skins/default/fonts/Platform-Regular-Web.eot?#iefix') format('embedded-opentype'),
  url('/skins/default/fonts/Platform-Regular-Web.woff2') format('woff2'),
  url('/skins/default/fonts/Platform-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.Platform-Regular-Web {
  font-family: 'Platform Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  letter-spacing: 1px;
}


@font-face {
  font-family: 'Platform Web';
  src: url('/skins/default/fonts/Platform-Light-Web.eot');
  src: url('/skins/default/fonts/Platform-Light-Web.eot?#iefix') format('embedded-opentype'),
  url('/skins/default/fonts/Platform-Light-Web.woff2') format('woff2'),
  url('/skins/default/fonts/Platform-Light-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

.Platform-Light-Web {
  font-family: 'Platform Web';
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
  letter-spacing: 1px;
}

/* Clear Floats */
.clearfix { zoom: 1; }
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  zoom: 1;
}

/* COLORS ------------------------------------------------------------------------------------------------------------*/
$red: #e84242;
$grey: #ebebeb;
$light_grey: #afafaf;

.red{ color: #ff0000; }
.black{ color: black; }
.grey{ color: #ebebeb; }
.light_grey{ color: #afafaf; }

html{
  font-size:1em;
}

a{
  color:$red;
}
h1 {
  font-size: 2.2rem;
  font-weight:400;
  letter-spacing:1px;
  font-family: 'Platform Web';
  }
h3 {
  font-size:1rem;
}


@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
  transform: translateZ(0);

}


@mixin box-sizing() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin flex-center() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

//Override Cookie tab
.cc-revoke {
  display: none!important;
}

.ie--object-fit {
  background-size: cover;
  background-position: center center;
  transition: all 300ms ease-out;
  height:100%;
}

.ie--object-fit img {
  opacity: 0!important;
}

.widget--loading {
  background: white url('../graphics/gallery-loading.gif') 50% 50% no-repeat!important;
  img {
    opacity:0;
    @include transition(all 0.8s linear);
  }
  &.widget--loaded {
    background: transparent;
    img { opacity:1 };
  }
}

.menu_row {

  line-height: 52px;

  overflow: hidden;
  a, span {
    font-size:1.1rem;
    padding: 15px 10px;
    color: black;
    letter-spacing:.05rem;
    &:hover { color:$red; }
  }
  li {
    &:first-child { padding-left:20px; }
    float: left;
  }
  > li.on > a { color:$red; }

}

.content--centered--width{
  max-width:1280px;
  margin: 0 auto;
}


@import 'widgets';
@import 'columns';
@import 'projects';
@import 'search';
@import 'forms';
@import 'mobile';

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size:.9rem;
  background: white
}

ul {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
.mt-10{margin-top:10px;}
.mt-20{margin-top:20px;}
.mt-20{margin-top:20px;}
.mt-40{margin-top:40px;}
.mb-10{margin-bottom:10px;}
.mb-20{margin-bottom:20px;}
.mb-20{margin-bottom:20px;}
.mb-40{margin-bottom:40px;}


.background--grey {
  background: $grey;
  //background: #CCCCCC;
}

@media screen and (max-width: 700px) {
  .widget--gallery.background--grey {
    background: none;
  }

  .visible-mobile {
    visibility: visible;
  }
}

@media screen and (min-width: 701px) {
  .visible-mobile {
    visibility: hidden;
  }
}

.background--white {
  background: white;
}

img {
  display: block;
}

.owl-item {
  float: left;
}

.hardwareAccel {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.animate400 {
  -moz-transition: all 400ms ease-out;
  -webkit-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
}

.animate300 {
  -moz-transition: all 300ms ease-out;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.animate200 {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

/* FONTS      --------------------------------------------------------------------------------------------------------*/
.font--wieght-500 {
  font-weight: 500;
}

.font--wieght-400 {
  font-weight: 400;
}

.font--wieght-300 {
  font-weight: 300;
}

/* DECORATORS --------------------------------------------------------------------------------------------------------*/
.decorator__button {
  margin-top: 5px;
  display: inline-block;
  position:relative;
  z-index:10;  //todo: change z-index back
  a {
    display: block;
    padding: 15px 22px;
    @include transition(all .3s linear);
  }
}

.decorator__button--red {
  @extend .decorator__button;
  a {
    font-size:1.125rem;
    color: white;
    background: $red;
    &:hover {
      color: $red;
      background: white;
    }
  }
}

.decorator__button--white {
  @extend .decorator__button;
  a {
    color: black;
    background: white;
  }
}

.decorator__button--small {
  a {
    padding:2px 0;
    background: none;
    color:$red;
  }
}

.decorator__icon {
  @include transition(all .3s linear);
  display:block;
  position:absolute;
  content:'';
  width:16px;
  height:16px;
  right: 11px;
  top: 7px;
}

.widget__share {
  right:0;
  color:inherit;
  font-size:0.9rem;
  position:relative;
  a {
    height:25px;
    padding-left:20px;
    display:block;
  }
  &:before {
    position:absolute;
    bottom:7px;
    left:0;
    content: '';
    display:block;
    width: 16px;
    height:16px;
    background: url(../graphics/icon_share.png);
  }
  .widget__share--options {
    position:absolute;
    width: 100px;
    right: -130px;
    bottom: 0px;
    padding: 5px;
    @include transition(all .2s linear);
    span {
      float:left;
      width:22px;
      height:22px;
      margin: 0 0 0 1px;
      @include transition(all .6s linear);
    }
    .stButton { margin:0; }
  }
}

.widget__share.active {
  .widget__share--options {
    right: 0;
    height: 22px;
  }
}

/* ROUND BUTTON ------------------------------------------------------------------------------------------------------*/
.round__button {
  a {
    display: block;
    width:20px;
    height:20px;

    background-color: white;
    border: 2px solid $red;
    border-radius: 50%;

    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;

    @include transition(all .3s linear);

    &:hover {
      background-color: $red;
      border: 2px solid white;
    }

  }

}

.round__button.round__button__leftbutton a{
  background-image: url(../graphics/arrow-left-red.svg);
}

.round__button.round__button__rightbutton a{
  background-image: url(../graphics/arrow-right-red.svg);
}

.round__button.round__button__leftbutton a:hover{
  background-image: url(../graphics/arrow-left-white.svg);
}

.round__button.round__button__rightbutton a:hover{
  background-image: url(../graphics/arrow-right-white.svg);
}

.round__button.round__button--black{
  a {
    border: 2px solid #000;
    &:hover {
      background-color: white;
      border: 2px solid $red;
    }
  }


}

.round__button__leftbutton.round__button--black a{
  background-image: url(../graphics/arrow-left-black.svg);
}

.round__button__rightbutton.round__button--black a{
  background-image: url(../graphics/arrow-right-black.svg);
}


.decorator__button--project:after {
  @extend .decorator__icon;
  background: url(../graphics/icon_projects.png);
}

.decorator__button--more:after {
  @extend .decorator__icon;
  background: url(../graphics/icon_right-arrow-small.png);
}

.decorator__button--who:after {
  @extend .decorator__icon;
  background: url(../graphics/icon_team-member.png);
}

/* HEADER ------------------------------------------------------------------------------------------------------------*/
#content {
  overflow: hidden;
  margin-top:54px;
  background: white;
  @include transition(margin .3s linear);
  &.content--submenu {
    margin-top:108px;
  }
}

#header {

  position:fixed;
  top:0;
  height: 54px;
  width: 100%;
  background: white;
  z-index:15;

  .header__logo {
    width: 115px;
    height: 45px;
    background: url(../graphics/logo.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    right:0;
    overflow: hidden;
    a {
      width: 100%;
      display: block;
      height: 100%;
      text-indent: -50px;
    }
  }
  .header__menu {
    border-bottom: 1px solid $red;
    height:53px;
    > ul {
      float:left;
      @extend .menu_row;
      ul {
        height:0;
        left:0;
        position: absolute;
        width: 100%;
        z-index: 2;
        background: $red;
        overflow: hidden;
        @include transition(height .3s linear);
        &.on { height:55px; }
        li.on a { color: black; }
        a { color:white; }
      }
      > li {
        &:hover ul { height:55px; }
      }
    }
    #searchForm{
      margin-top:15px;
      float:left;
    }
  }
  .header__social_link{
    position: absolute;
    top:17px;
    right:130px;
    a{
      display:inline-block;
      margin-right:10px;
    }
  }

}

#header .header__menu > ul {
  li.on ul {
    height:auto;
    background: white;
    border-top:1px solid $red;
    border-bottom:1px solid $red;
    li.on a { color:$red; }
    li a {
      color: black;
      &:hover { color: $red; }
    }
  }
  li ul {
    li.on a { color:$red; }
    li a {
      color: white;
      &:hover { color: black; }
    }
  }
}

/* FOOTER ------------------------------------------------------------------------------------------------------------*/
#footer {
  height: 160px;
  background: $grey;
  ul {
    line-height: 160px;
    display: block;
    text-align: center;
    li {
      display: inline-block;
      margin-right: 15px;
      color: #969696;
      font-size: 0.8rem;
      a {
        padding: 10px;
        color: inherit;
        text-transform: uppercase;
      }
      &:last-child { margin-right: 0; }
    }
  }
}

/* MOBILE MENU -------------------------------------------------------------------------------------------------------*/
#mobile__menu {
  position:fixed;
  display:none;
  li a {
    color:black;
  }
}

body .mm-menu {
  background: white!important;
}

body .mm-opened {
  .mm-panels {
    opacity:1;
  }
}

body .mm-panels {
  opacity:0;
}

#mm-blocker {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
}

body .mm-listview>li:not(.mm-divider):after {
  left:0;
}

.mobile__button {
  display:none;
  width:32px;
  height:32px;
  position: absolute;
  top: 11px;
  left: 5%;
  font-size:23px;
  color:$red;
  text-align: center;
}

#slide__menu {
  background: #f7f7f7;
}

@media screen and (max-width: 1280px) {
  html{
    /*font-size:.8rem;*/
  }
}

