#projects {
  .col--sizer {
    opacity:0;
    pointer-events: none;
    img {
      width: 100%;
    }
  }

  .projects__item {
    border:1px solid white;

    img {
      width:100.5%;
      display:block;
    }
    .project__content {
      a {
        overflow: hidden;
        display:block;
        position:relative;
        .project__title {
          padding-left:25px;
          background: rgba(255,255,255,0.9);
          height: 40px;
          line-height: 40px;
          width: 100%;
          position:absolute;
          bottom:0;
          color:$red;
          span { color:black; }
        }
      }
    }
  }
}

#project .widget__share {
  &.active .widget__share--options {
    right: -4px;
    background:white;
  }
  margin: 20px 0 40px 0;
}

#project .widget--copy .widget__detail {
  > div {

    width: 60%;

    h3{
      margin-top:0;
    }

  }
}

#projects .projects__item.launchright{
  left:50%;
}

#projects .projects__item:hover {
  .project__content {
    .project__title {
      color:white;
      background:rgba($red, 0.9);
    }
    .project__title span { color: black; }
  }
}

#projects  .col--container {
  //width:1246px;
  top: -2px;
  margin-left: -2px;
  margin-right:-2px;
  @include transition(all .6s linear);
}

/* PROJECT -----------------------------------------------------------------------------------------------------------*/
.projects_menu {
  position: relative;
  background: white;
}

.project__info {
  font-size: 1rem;
  margin: 15px 0;

  strong {
    display:inline-block;
    width:170px;
  }
}

#projects_menu__filters {

  span {
    text-transform:uppercase;
    padding-left: 30px;
    float:left;
    line-height:54px;
    font-size: 1rem;
    margin-right:10px;
    font-weight:700;
    color:#bebebe;
  }
  > li { border-bottom:1px solid $red; }
  li > ul {
    @extend .menu_row;
    @extend .clearfix;
  }

  .projects_menu__filter {

    float:left;
    color:black;

    &:hover { color:black; }
    &.clear a { color:#bebebe; }
    &.disabled {
      cursor:none;
      color:#f2f2f2 !important;
      a{
        color:#f2f2f2 !important;
        display:none;
      }
      cursor:none;
    }
    &.active { color:$red; }
    a { color:inherit; }
  }

}

#project {
  p { font-size: 1rem; }
  .project__breadcrumb {
    @extend .menu_row;
    .breadcrumb__other, .breadcrumb__featured {
      a { color:#bebebe; }
    }
    li {color: $red; }
    li span {
      padding:15px 0 15px 10px;
      font-size:0.9rem;
      color:black;
    }
    a {
      font-size:0.9rem;
      padding:15px 0;
      color: inherit;
      &:hover { color:$red; }
    }
  }
  .project__detail  .project__share { color:$red; }
  .project__header {
    background: $grey;
    min-height:100px;
    line-height: 100px;
    position: absolute;
    width: 100%;
    z-index: 999;
    //bottom: 0%;


    @media (min-width: 1040px) {
      //top: 1.3rem;

    }

    > div {
      max-width: 960px;
      margin: 0 auto;
      overflow: hidden;
      > div {
        float:left;
        display:block;
      }
    }
    .project__title {
      background: $grey;
      width: 70%;
      h1 {
        display:block;
        margin:0;
        font-size: 1.8rem;
        line-height: 6rem;
        &.h1--long {
          padding-top: 26px;
          padding-bottom: 26px;
          line-height: 40px;
          max-width: 600px;
        }
        span { font-weight: 400; }
      }
    }
  }
}

body #projects_menu__filters li > ul li:first-child {
  padding-left:0;
}

#project {
  .widget--copy {
    padding:0;
    text-align: initial;
    .widget__detail {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;
     // padding:3% 0 0 0;
     padding: 110px 0px 0px 0px;

      .col--2xs {
        width:70%;
        padding-right: 10%;
      }
      .col--1xs {
        width:30%;
      }
      .col--1xs:first-child {
        width: 70%;
        padding-right: 10%;
      }
      table {
        font-size:1rem;

      }
      .col--1xs:last-child {
        p {
          font-style:italic;
          color: $light_grey;
          font-weight: 300;
        }
      }
    }

    table{
      margin:15px 0;
      width:50%;
      p{
        margin:4px;
        min-height: 21px;
      }
      td {
        vertical-align: top;
        padding: 0;
        &:nth-child(1) p {
          font-weight: bold;
          margin-left:0;
        }
      }
    }

    .widget__detail:first-child {
      > div { width:73%; }
    }
    .widget--person .widget__title {
      margin-top:25px;
    }
    .widget--person img {
      margin-bottom:30px;
      max-width:100%;
    }
  }
}

.project__next {
  padding-right: 45px;
  top:0;
  right:0;
  position:absolute;
  line-height: 54px;
  height: 54px;
  ul li {
    float:left;
    color: #bebebe;
    a {
      font-size: 0.9rem;
      padding: 15px 10px;
      color: inherit;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.project_arrows {
  position: absolute;
  left: -25px;
  top: 10px;
  display:block;
  content:'';
  width:32px;
  height:32px;
}

.project__next ul li {
  a { cursor: default; }
  &:first-child:before {
    @extend  .project_arrows;
    background: url(../graphics/icon_left-arrow-small-ro.png) 50% 50% no-repeat;
  }
}

.project__next ul li {
  a { cursor: default; }
  &:last-child:after {
    @extend  .project_arrows;
    left:auto;
    right: 20px;
    background: url(../graphics/icon_right-arrow-small-ro.png) 50% 50% no-repeat;
  }
}

.project__next ul li.active {
  a { cursor: pointer; }
  a:hover { color:black; }
  &:first-child:before {
    background: url(../graphics/icon_left-arrow-small.png) 50% 50% no-repeat;
  }
}

.project__next ul li.active {
  a { cursor: pointer; }
  a:hover { color:black; }
  &:last-child:after {
    background: url(../graphics/icon_right-arrow-small.png) 50% 50% no-repeat;
  }
}
.inline-block{display:inline-block;}
/* MOBILE     --------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1025px) {

  #project .project__breadcrumb li span {
    padding-left:30px;
  }

  #project .project__header > div {
    max-width:100%;
    margin:0;
    padding-left:6%;
  }

  #project .widget--copy .widget__detail {
    width:88%;
    padding-top: 30px;
  }

  #project .widget--copy .widget__detail .col--1xs:first-child,
  #project .widget--summary .widget__detail .col--1xs:first-child {
    padding-right:0!important;
    width:100%;
  }

  #project .widget--copy .widget__detail .col--2xs,
  #project .widget--copy .widget__detail .col--1xs {
    width:100%;
  }

  // #project .widget--copy .widget__detail { /*padding: 6%;*/ padding: 108px 6% 6% 6%; }

  #project .widget--copy .widget--person img {
    float: left;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  #project .project__breadcrumb {
    ul {
      padding: 10px 0;
      overflow: hidden;
      li {
        line-height: 30px;
        padding-left:0!important;
        margin-right:20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .project__next {
    padding-left: 6%;
    li:first-child a {
      padding-left:0;
    }
  }

  #project .project__breadcrumb li span {
    padding-left:0;
  }

  #project .project__breadcrumb ul {
    padding-left:6%;
  }

  #project .project__header {
    position: relative;
  }

  #project .project__header .project__title {
    width:100%;
    padding-top: 15px;
    h1 {
      line-height: 30px;
    }
  }

  #project .project__header > div > div {
    line-height: 20px;
  }

  #project .project__header > div > div.owl-dots {
    margin-top:55px;
  }

  body #project .owl-dots {
    text-align: left;
    float: none !important;
  }

  #projects_menu__filters span { float: none; }

  #projects_menu__filters li > ul {
    line-height: 40px;
    margin: -10px 0 8px 20px;
    a { padding: 5px 10px; }
  }

  .project__next  {
    position:relative;
    ul {
      li:last-child:after { display:none; }
    }
  }
}

@media screen and (max-width: 480px) {
  #project .project__header .project__title h1 {
    font-size: 1.3rem;
    &.h1--long {
      padding-top:initial;
      padding-bottom: initial;
      line-height: initial;
      max-width: initial;
    }
  }
  #project .project__header.title--long .owl-dots {
    line-height: 60px;
    height: 80px
  }
}
