.widget--copy {
  h2 {
    margin: 0;
    text-align: center;
    font-weight: 300;
    font-size: 2.2em;
  }
  .widget__detail {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    position: relative;
    img {
      max-width: 100%;
      margin-bottom: 2px;
    }
    h2, h3 {
      @extend .Platform-Light-Web;
      font-size: 2.2rem;
      font-weight: 300;
      line-height: 2.6rem;
    }
    .widget__caption {
      padding: 0 4%;
      // position: absolute;
      top: 0;
      bottom: 0;
      background: white;
      @include flex-center;
    }
    p:first-child {
      margin-top: 0;
    }
    .col--1xs:first-child {
      padding: 0 2.5% 0 0;
    }
    .col--1xs:last-child {
      padding: 0 5% 0 2.5%;
    }
  }
}

.widget--copy {
  p {
    margin: 15px 0;
  }
  .widget--copy--person {
    margin-top:8%;
  }
  .widget__image {
    img {
      width: 90%;
      margin: 0 auto 5% auto;
      max-height: 400px;
      object-fit: cover;
    }
  }
}

.widget--copy .widget__detail.col--row2 {
  padding-top: 0;
  max-width: 750px;
  .widget--copy {
    padding-left: 10%;
  }
}

.widget--copy .widget--copy--person {
  h1 {
    font-weight: 400;
  }
}

.widget--copy.widget--full {
  padding: 0;
  .widget__detail {
    max-width: 100%;
  }
}

.widget--copy.widget--right {
  min-height: 300px;
  .widget__image {
    float: right;
  }
}

.widget--copy.widget--left {
  min-height: 300px;
  .widget__caption {
    left: 50%;
  }
}

.widget--person {
  .widget__title a {
    color: $red;
    font-style: initial;
    font-size: 1rem;
  }
  .widget__image {
    max-width: 150px;
    max-height: 150px;
    img {
      height: 150px;
      max-width: 100%;
    }
  }
}

.widget--copy .widget__share {
  width: 98px;
  overflow: hidden;
  margin-top: 50px;
  .widget__share--options {
    bottom: -3px;
  }
}


/* MOBILE     --------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 1281px) {
  .widget--copy {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (max-width: 1024px) {
  .widget--copy {
    //padding: 5%!important;
    .widget--copy--person {
      margin-top:0;
    }
  }
  
  .widget--copy .widget__detail h2,
  .widget--summary .widget__detail h2 {
    line-height: 2.7rem;
  }
  
  .widget--copy .widget__detail .col--1xs:last-child {
    padding:0;
  }
}

@media screen and (max-width: 840px) {
  .widget--copy .widget__detail h3 {
    font-size:1.3rem;
    line-height: inherit;
  }
}

@media screen and (max-width: 768px) {
  .widget--copy .widget__detail .col--1xs:first-child {
    padding: 0;
  }
}