.col--container [class*="col"] {
  float:left;
  @include box-sizing();
}

.col--container:after {
  content: '';
  display: block;
  clear: both;
}

.col--container.col--row4 {
  .col--1xs { width:25%; }
  .col--2xs { width:50%; }
}

.col--container.col--row3 {
  .col--1xs { width:33.332%; }
  .col--1-5xs { width:50%!important; }
  .col--2xs { width:66.65%; }
}

.col--container.col--row2 {
  .col--1xs { width:50%; }
  .col--2xs { width:100%; }
}

/* MOBILE     --------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1281px) {
  #Projects .col--container { padding:0; }
  .widget > .col--container {
    //padding:2%;
  }
}

@media screen and (max-width: 1025px) {
  .widget > .col--container.col--row3 {
    .col--1xs { width:50%; }
    .col--2xs { width:100%; }
  }
}

@media screen and (max-width: 768px) {
  .widget > .col--container.col--row3 .col--1xs {
    width:100%;
  }

  .col--container.col--row3 .col--1xs { //projects
    width:50%;
  }

  .col--container.col--row2 .col--1xs {
    width:100%;
  }

  .col--container.col--row4 .col--2xs {
    width:100%;
  }

  .col--container.col--row4 .col--1xs {
    width:50%;
  }

  .col--container.col--row3 .col--1-5xs {
    width:100%;
  }
}

@media screen and (max-width: 610px) {
  .col--container.col--row3 .col--1xs {
    width:100%;
  }

  .col--container.col--row3 .col--1-5xs {
    width:100%!important;
  }
}